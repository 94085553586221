@import "../../../variables";

.user-menu {
  z-index: 999;
  position: absolute;
  right: 0px;
  top: 48px;
  width: 252px;

  .list-group-item {
    padding: 0;
    margin: 0 10px;
    border: 0;

    &:hover {
      a,
      div,
      .fa {
        color: $color-primary;
      }
    }

    a,
    div {
      text-decoration: none;
      width: 100%;
      color: #5f5f5f;
      padding: 10px;
      display: block;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;

      .fa {
        color: #939393;
      }
    }
  }
}
:focus {
  outline: none !important;
}
