.notif-container {
  color: black;

  overflow-y: auto;
  .notif-unread {
    background: #f1f2fe;
  }
  .notif-content {
    font-size: 14px;
    font-weight: 400;
  }
}

.notif-arrow {
  position: relative;
  background: #040ca5;

  &.notif-closed:after {
    display: none;
  }
  &:after {
    top: 36px;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(4, 12, 165, 0);
    border-top-color: #040ca5;
    border-width: 16px;
    margin-left: -16px;
  }
}

@media (max-width: 375px) {
  .notif-container {
  }
}
