@import "./variables.scss";

html,
body,
#root {
  min-height: 500px;
  background: #ffffff;
}
body {
  margin: 0;
  font-family: Satoshi;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.wrapper {
  min-height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.progress_text {
  font-size: 8px;
  color: #4c4c4c;
  position: absolute;
  opacity: 1;
  transition: opacity 1000ms ease-out;
  mix-blend-mode: difference;
  width: 100%;
  text-align: right;
  height: 10px;
  line-height: 10px;
  border-radius: 10px;
  top: 6px;
}
.overlay_loading {
  position: absolute;
  z-index: 999;
  height: 104%;
  width: 104%;
  left: -2%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffb8;
}

.btn-group {
  display: flex;
  flex-direction: column;
}
.d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-header {
  background: #2196f3 linear-gradient(180deg, #42a6f5, #2196f3) repeat-x;
  color: white;
}
.breadcrumb-custom {
  background: #299af3;

  .col {
    height: 25px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: #aad2f3;
      border-right: 13px solid #c0e3ff;
      transform-origin: 100% 100%;
      transform: skewX(30deg);
      transition: 1s;
    }

    span {
      color: white;
      position: absolute;
      line-height: 25px;
      left: 3%;
    }

    .active {
      &:before {
        background: #2196f3 linear-gradient(180deg, #42a6f5, #2196f3) repeat-x;
        border-right: 13px solid rgb(107, 190, 255);
      }
    }
  }
}

.load_ls_overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff9c;
  z-index: 99;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
}
.Toastify__toast {
  font-family: Satoshi !important;
  border-radius: 5px !important;
  font-weight: 800 !important;
  font-size: 12px !important;
  padding: 0 15px !important;
  min-height: 64px !important;
}
.Toastify__toast--error {
  background: $color-danger !important;
  color: white;
  .Toastify__progress-bar {
    background: white !important;
  }
}
.Toastify__toast--warning {
  background: $color-warning !important;
  color: $color-success;
}
.Toastify__toast--success {
  background: #8ed6e0 !important;
  color: $color-success;
  .Toastify__progress-bar {
    background: $color-success !important;
  }
}
.Toastify__close-button {
  align-self: center !important;
}
.stop-jittery {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

@media (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0px !important;
  }
}

.card {
  overflow: hidden;
  .card-header {
    text-align: center;
  }
}
.navbar-brand {
  font-size: 18px;
}
.login-sidenav::before {
  content: "";
  //background-image: url(/images/main-bg.jpeg);
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.table {
  background: white;
  box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius;
  overflow: hidden;

  thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6;
  }
  .tr-hover {
    cursor: pointer;
    td {
      background: white;
    }
  }
}

.form-text {
  text-align: center;
}

.modifyInputBtn {
  opacity: 0.4;
  top: 27px;
  right: 0px;
  height: 39px;
  padding: 0 12px;
}
.emailContainer:hover .modifyInputBtn {
  opacity: 1;
}
.resendMail {
  .overinfo {
    display: none;
    text-transform: none !important;
  }

  &:hover,
  &.isactive {
    .overinfo {
      display: inline-block;
    }
  }
}

.highlight {
  background: yellow;
}

.acc-btn:hover {
  background: rgba(128, 128, 128, 0.01) !important;
}
.grecaptcha-badge {
  opacity: 0;
  right: -100% !important;
}

.react-calendar {
  border: none !important;
  width: 100% !important;
}
.react-calendar__tile {
  // border-radius: 40px;
}
.react-calendar__tile--active {
  background: $color-primary !important;
  box-shadow: 0px 1px 9px -2px grey;
}
.react-calendar__navigation button {
  // border-radius: 40px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 24px;
  padding: 0;
  padding-bottom: 2px;
}
.react-calendar__navigation button[disabled] {
  opacity: 0;
}
.react-calendar__tile {
  transition: 400ms;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-calendar__navigation__label__labelText {
  font-size: 1.3rem !important;
  text-transform: capitalize;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: none;
}
.react-calendar__month-view__days__day--weekend {
  color: black !important;
}
.react-calendar__tile--active {
  color: white !important;
}
.react-calendar__tile--now {
  background: #c7f9ff;
}
.StripeElement {
  border-radius: 5px;
  padding: 10px !important;
  padding-right: 15px !important;
  height: 48px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid $color-primary;
  border-radius: 4px;
  margin: 10px 0;
  box-shadow: none;
  transition: 200ms;
}

.StripeElement--focus {
  border: 1px solid $color-primary !important;
  background-color: white;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: white !important;
}
.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-grey {
  color: #939393 !important;
}
.hidden {
  display: none;
}
.custom-p {
  font-size: 14px;
  line-height: 22px;
}
.custom-ul {
  line-height: 20px;
  padding-left: 0;
  padding-left: 16px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.slick-dots li button:before {
  font-size: 12px !important;

  width: 30px !important;
  height: 30px !important;
}
.bg-transparent {
  background: rgba(0, 0, 0, 0) !important;
}
.bg-greylight {
  background: rgb(241, 242, 254) !important;
}
.shadow-small {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 2px -3px !important;
}
.rounded {
  border-radius: 10px !important;
}
.buttonSize {
  max-width: 140px;
  min-width: 19%;
}
.custom-card-link:hover {
  text-decoration: none;

  & .custom-card-link-content {
    background: #f1f2fe;
  }
}
.text-sm {
  font-size: 12px;
}

.bg-sepa {
  background: #03566d;
}

.read-toogle:hover .read-tooltip {
  opacity: 1;
  pointer-events: auto;
}

.read-tooltip {
  position: absolute;
  right: 0px;
  top: -25px;
  padding: 0px 10px;
  background-color: #ededed;
  text-align: center;
  border-radius: 20px;
  font-weight: 500;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  border: 1px solid #cacaca;
  transition: opacity 0.2s ease-in-out;
}
