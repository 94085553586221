:root {
  --color-success: #040ca5;
  --color-primary: #3e75ff;
}

$border-radius: 5px;
$ligthtingIntensity: 10%;
$darkeningIntensity: 10%;
$body: #959ca7;

$color-primary: #3e75ff;
$color-primary-light: lighten($color-primary, $ligthtingIntensity);
$color-primary-dark: lighten($color-primary, $darkeningIntensity);

$color-success: #040ca5;
$color-success-light: lighten($color-success, $ligthtingIntensity);
$color-success-dark: darken($color-success, $darkeningIntensity);

$color-info: #009404;
$color-info-light: lighten($color-info, $ligthtingIntensity);
$color-info-dark: darken($color-info, $darkeningIntensity);

$color-danger: #dc5f45;
$color-danger-light: lighten($color-danger, $ligthtingIntensity);
$color-danger-dark: lighten($color-danger, $darkeningIntensity);

$color-warning: #ffcc99;
$color-warning-light: lighten($color-warning, $ligthtingIntensity);
$color-warning-dark: lighten($color-warning, $darkeningIntensity);

$color-dark: #6e6e71;
$color-dark-light: lighten($color-dark, $ligthtingIntensity);
$color-dark-dark: lighten($color-dark, $darkeningIntensity);

$color-secondary: #3e75ff;
$color-secondary-light: lighten($color-secondary, $ligthtingIntensity);
$color-secondary-dark: darken($color-secondary, $darkeningIntensity);

$color-light: #f2f3fa;
$color-light-light: lighten($color-light, $ligthtingIntensity);
$color-light-dark: darken($color-light, $darkeningIntensity);

$color-default: #ffffff;
$color-default-light: lighten($color-default, $ligthtingIntensity);
$color-default-dark: darken($color-default, $darkeningIntensity);

$color-tertiary: #afc8cb;
$color-tertiary-light: lighten($color-tertiary, $ligthtingIntensity);
$color-tertiary-dark: darken($color-tertiary, $darkeningIntensity);

$color-event-primary: $color-primary;
$color-event-secondary: #8ed6e0;
$color-event-success: $color-success;
$color-event-light: #aaabb7;
$color-event-danger: $color-danger;
$color-event-warning: $color-warning;
$color-event-info: $color-info;
$color-event-tertiary: #860f0f;

:export {
  primary: $color-primary;
  primaryLight: $color-primary-light;
  primaryDark: $color-primary-dark;
  success: $color-success;
  successLight: $color-success-light;
  successDark: $color-success-dark;
  info: $color-info;
  infoLight: $color-info-light;
  infoDark: $color-info-dark;
  danger: $color-danger;
  dangerLight: $color-danger-light;
  dangerDark: $color-danger-dark;
  warning: $color-warning;
  warningLight: $color-warning-light;
  warningDark: $color-warning-dark;
  dark: $color-dark;
  darkLight: $color-dark-light;
  darkDark: $color-dark-dark;
  secondary: $color-secondary;
  secondaryLight: $color-secondary-light;
  secondaryDark: $color-secondary-dark;
  light: $color-light;
  lightLight: $color-light-light;
  lightDark: $color-light-dark;
  tertiary: $color-tertiary;
  tertiaryLight: $color-tertiary-light;
  tertiaryDark: $color-tertiary-dark;
  event-primary: $color-event-primary;
  event-secondary: $color-event-secondary;
  event-success: $color-event-success;
  event-light: $color-event-light;
  event-tertiary: $color-event-tertiary;
  event-danger: $color-event-danger;
  event-warning: $color-event-warning;
  event-info: $color-event-info;
}
