@import "./variables.scss";

@media (max-width: 1400px) {
  .acc-btn {
    flex-direction: column;
  }
  .user-title-container {
    .user-title-header {
      // display: block !important;
      flex-direction: column !important;
      width: 100%;
      width: 100%;
      text-align: center !important;

      .user-title-icon {
        width: 100%;
        margin-bottom: 10px;
        flex-direction: row !important;
        justify-content: center;
        margin-right: 0 !important;
        .fa {
          margin-right: 5px;
        }
      }
      .user-title-info {
        text-align: center !important;
        width: 92%;
      }
    }
    .user-title-content {
      justify-content: center !important;
      width: 100%;
      margin: 10px 0;
    }
  }
}

@media (max-width: 900px) {
  html,
  body {
    font-size: 14px;
    line-height: 18px;

    .form-control {
      font-size: 14px;
    }
  }
  .nav-tabs .nav-link {
    font-size: 17px;
    text-align: left;
  }

  .nav-tabs .nav-item {
    width: 100% !important;
  }
  .overlay-bottom {
    width: 100% !important;
  }
}

@media (max-width: 600px) {
  .readonly .input-group {
    width: 100%;
  }
  .readonly label {
    width: 100%;
    margin-top: 10px;
  }
}
