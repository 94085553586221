@import "../../../variables";

.vertical-timeline-element-content {
  position: relative;
  margin-left: 60px;
  background: #fff;
  border-radius: 0.25em;
  padding: 1em;
  box-shadow: 0 3px 0 #ddd;
}
.vertical-timeline-element-icon {
  margin-left: 4px;
  margin-top: 8px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05), 0 0 15px -3px #afafaf;
}
.vertical-timeline-element-date {
  font-weight: 600 !important;
  padding-bottom: 0 !important;
}
.vertical-timeline {
  padding: 0;
}
.vertical-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 1px;
  background: #939393;
}
.vertical-timeline-element-date {
  display: none !important;
}
// @media only screen and (min-width: 1170px) {
//   .vertical-timeline:before {
//     content: "";

//     left: 0px !important;
//   }
//   .vertical-timeline--two-columns
//     .vertical-timeline-element-content
//     .vertical-timeline-element-date {
//     position: absolute;
//     width: 100%;
//     left: 128%;
//     top: 13px;
//     font-size: 12px;
//   }
//   .vertical-timeline-element-icon {
//     margin-left: -16px !important;
//     margin-top: 16px;
//   }
//   .vertical-timeline--one-column .vertical-timeline-element-icon {
//     margin-left: 4px !important;
//     margin-top: 8px;
//   }
//   .vertical-timeline--two-columns
//     .vertical-timeline-element.vertical-timeline-element--right
//     .vertical-timeline-element-content
//     .vertical-timeline-element-date,
//   .vertical-timeline--two-columns
//     .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
//     .vertical-timeline-element-content
//     .vertical-timeline-element-date {
//     left: auto;
//     right: 127%;
//     text-align: right;
//   }
//   .vertical-timeline-element {
//     margin: 2em 0;
//   }
// }
