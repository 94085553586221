@import "./variables.scss";

h1,
.h1 {
  color: $color-success;
  font-weight: bold;
  margin-bottom: 0;
  font-size: 1.5rem;
}
h2,
.h2 {
  color: #bdbdbd;
}
h4,
.h4 {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 28px;
  color: $color-success;
}
