.document-timeline-container {
  position: relative;
  padding: 20px 0;
}

.document-timeline-container::before {
  content: "";
  position: absolute;
  left: 80px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #e0e0e0;
}

.document-timeline-item {
  position: relative;
  margin-bottom: 30px;
  padding-left: 100px;
}

.document-timeline-date {
  position: absolute;
  left: 0;
  top: 7px;
  width: 70px;
  text-align: right;
  font-size: 0.9em;
  color: #666;
}

.document-timeline-content {
  position: relative;
}

.document-timeline-content::before {
  content: "";
  position: absolute;
  left: -25px;
  top: 9px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #007bff;
  border: 2px solid white;
}
