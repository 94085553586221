@import "./variables.scss";

.form-group {
  scroll-padding: 100px 0px;
}
.form-control {
  box-shadow: none !important;
  border-radius: 10px !important;
  padding: 10px !important;
  padding-right: 15px !important;
  height: 48px;
  background: white;
  border: 1px solid $color-primary !important;
  height: 40px;
  font-weight: 600;
  color: black;
  border: none;
  color: $color-primary;
  font-size: 0.9rem;

  &.custom-range {
    border: 0 !important;
  }

  &:focus {
    border-color: $color-success !important;
    background-color: white;
    color: black;
  }
}
.form-control:disabled,
.form-control[readonly] {
  background-color: white;
  opacity: 1;
}
.form-control.range-inp-value {
  text-align: center !important;
  border-radius: 5px !important;
  padding: 0 !important;

  &:focus {
    transform: scale(1.6);
  }
}

label {
  margin-top: 12px;
  margin-bottom: 4px;
  font-weight: 500;
  margin-bottom: 3px;
  margin-top: 5px;
  color: $color-success;
  //text-indent: 10px;
  font-size: 14px;
  line-height: 18px;
}

.readonly {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  label {
    width: 30%;
    margin: 0;
  }

  .input-group {
    width: 70%;
  }
  .form-control {
    background: transparent !important;

    box-shadow: none !important;
    width: 100%;
  }
}

.input-group .input-group-append {
  background: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-right: 12px;
  border: none;
  display: flex;
  align-items: center;
  border-top: 1px solid $color-primary;
  border-bottom: 1px solid $color-primary;
  border-right: 1px solid $color-primary;
}
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border: 1px solid $color-success;
  background-color: white;
}

textarea[disabled],
textarea[disabled]::placeholder,
textarea.form-control[disabled],
textarea.form-control[disabled]::placeholder,
input.form-control[disabled],
input.form-control[disabled]::placeholder,
input[type="text"][disabled],
input[type="text"][disabled]::placeholder,
input[type="password"][disabled],
input[type="password"][disabled]::placeholder,
input[type="email"][disabled],
input[type="email"][disabled]::placeholder,
input[type="number"][disabled],
input[type="number"][disabled]::placeholder,
[type="text"].form-control[disabled],
[type="text"].form-control[disabled]::placeholder,
[type="password"].form-control[disabled],
[type="password"].form-control[disabled]::placeholder,
[type="email"].form-control[disabled],
[type="email"].form-control[disabled]::placeholder,
[type="tel"].form-control[disabled],
[type="tel"].form-control[disabled]::placeholder,
[contenteditable].form-control[disabled],
[contenteditable].form-control[disabled]::placeholder {
  color: #3e75ff;
  border-bottom: 0;
}

.DateInput_input {
  font-weight: 600 !important;
  box-shadow: none !important;
  border: 1px solid #3e75ff !important;
  border-radius: 10px !important;
  font-size: 0.9rem !important;
  padding: 5px 10px !important;
  color: #3e75ff !important;

  &:focus {
    color: black !important;
  }
}
.custom-control-label {
  text-indent: 0px;
}
.custom-control.custom-switch {
  padding-left: 50px;
}
.custom-switch .custom-control-label::before {
  left: -51px;
  width: 35px;
  top: -3px;
  pointer-events: all;
  border-radius: 0.625rem;
}
.custom-switch .custom-control-label::before {
  top: -2px;
  height: 0.875rem;
  background: #bbb;
  border: none;
  opacity: 1 !important;
  height: 22px;
  border-radius: 30px;
}
.custom-switch .custom-control-label::after {
  left: -49px;
  width: 17px;
  height: 17px;
  top: 0px;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: white;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $color-primary;
  background: $color-primary;
}

.password-input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: 0px !important;
}
